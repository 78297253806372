<template>
  <component
    :is="tag"
    :class="componentClasses"
    :disabled="isDisabled"
    :type="type"
    @click="$emit('action')"
  >
    <i
      v-if="iconName"
      :class="[
        iconName ? 'bx ' + iconName : '',
        { 'uikit-button__icon_right': hasRightIcon }
      ]"
    />
    <span
      v-if="title"
      class="uikit-button__title"
    >{{ title }}</span>
  </component>
</template>
<script>
  export default {
    name: 'DefaultButton',
    props: {
      tag: {
        type: String,
        default: 'button',
      },
      type: { // button, submit, reset
        type: String,
        default: 'button',
      },
      title: {
        type: String,
        default: '',
      },
      form: { // square, circle, rectangle, ellipse
        type: String,
        default: 'square',
      },
      size: { // в зависимости от формы: смотри css
        type: String,
        default: 'm',
      },
      color: { // primary, neutral, transparent, notify, success, danger
        type: String,
        default: '', // оставить пустым, иначе не лягут кастомные свойства
      },
      iconName: { // box-icons
        type: String,
        default: '',
      },
      hasRightIcon: {
        type: Boolean,
        default: false,
      },
      isFulled: { // width: 100%
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      hasPaddings: {
        type: Boolean,
        default: true,
      },
      titleColor: { // для нетиповой стилизации
        type: String,
        default: '',
      },
      hoveredTitleColor: { // для нетиповой стилизации
        type: String,
        default: '',
      },
      backgroundColor: { // для нетиповой стилизации
        type: String,
        default: '',
      },
    },
    computed: {
      componentClasses () {
        return [
          'uikit-button',
          this.color ? 'uikit-button_color_' + this.color : '',
          this.form ? 'uikit-button_form_' + this.form : '',
          this.size ? 'uikit-button_size_' + this.form + '_' + this.size : '',
          { 'uikit-button_fulled': this.isFulled },
          { 'uikit-button_without-paddings': !this.hasPaddings },
          this.titleColor ? this.titleColor : '',
          this.hoveredTitleColor ? this.hoveredTitleColor : '',
          this.backgroundColor ? this.backgroundColor : '',
        ];
      },
    },
  };
</script>

<style lang="scss">
.uikit-button {
  font-family: 'Inter', sans-serif;
  @include default-button;
  gap: 4px;

  &_size {
    &_rectangle {
      display: flex;
      align-items: center;

      &_l {
        gap: 8px;
        padding: 0px 8px;
        height: 40px;

        i {
          font-size: 24px;
        }

        span {
          @include text_m;
        }
      }

      &_m {
        gap: 8px;
        padding: 0px 12px;
        height: 32px;

        span {
          @include text_s;
        }
      }

      &_s {
        gap: 8px;
        padding: 0px 8px;
        height: 24px;

        span {
          @include text_xs;
        }
      }

      &_s, &_m {
        i {
          font-size: 16px;
        }
      }
    }
    &_square, &_circle, &_ellipse {
      &_l {
        height: 40px;
        padding: 0 8px;

        i {
          font-size: 24px;
        }

        span {
          @include text_m;
        }
      }

      &_m {
        height: 32px;
        padding: 0 8px;
        span {
          @include text_s;
        }
      }

      &_s {
        padding: 0 8px;
        height: 24px;
        span {
          @include text_xs;
        }
      }

      &_s, &_m {
        i {
          font-size: 16px;
        }
      }
    }
  }

  &_form {
    &_square, &_rectangle {
      border-radius: 4px;
    }

    &_circle {
      border-radius: 50%;
    }

    &_ellipse {
      border-radius: 100px;
    }
  }

  &__icon {
    &_right {
      order: 2;
      margin-left: 4px;
    }
  }

  &_fulled {
    width: 100%;
  }

  &_without-paddings {
    padding: 0 !important;
  }

  &_color {
    &_primary {
      color: $t-light-white;
      background: var(--primary-cl);

      &:hover {
        opacity: 0.7;
      }
    }

    &_neutral {
      color: var(--color-secondary);
      background: var(--neutral-100);

      &:hover {
        background: var(--neutral-200);
      }
    }

    &_transparent {
      color: var(--primary-cl);

      &:hover {
        color: $cl-brand-hover;
      }
    }

    &_danger {
      color: $cl-danger;
      background: $cl-danger-bg;

      &:hover {
        background: $cl-danger-hover;
      }
    }

    &_success {
      color: $cl-success;
      background: $cl-success-bg;

      &:hover {
        background: $cl-success-hover;
      }
    }
  }

  &__title {
    white-space: nowrap;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
